export const headerComponentParams = {
  menuIcon: 'fa-bars',
  template: `<div class="ag-cell-label-container" role="presentation">  
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" ></span>  
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">    
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>    
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>    
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>    
                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>    
                        <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: pre-line; word-break: break-all;
                        word-spacing: -1px;"></span>    
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>  
                    </div>
                </div>`
}

export const headerComponentParamsInfo = {
    menuIcon: 'fa-bars',
    template: `<div class="ag-cell-label-container" role="presentation">  
                      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" ></span>  
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">    
                          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>    
                          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>    
                          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>    
                          <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>    
                          <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: pre-line; word-break: break-all;
                          word-spacing: -1px; margin-right: 5px;"></span><span class="ag-icon ag-icon-eye"></span>
                          <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>  
                      </div>
                  </div>`
  }

import date from 'date-and-time'

export const getNormalizedDate = (currentDate, fmt = `MM/dd/yy`) => {
  if (currentDate === null || currentDate === undefined) {
    return undefined
  }
  if (currentDate) {
    return date.format(toDateWithoutOffset(currentDate), fmt)
  }
  return ``
}

export const getNormalizedDateAfterShifted = (
  currentDate,
  fmt = `MM/dd/yy`
) => {
  if (currentDate === null || currentDate === undefined) {
    return undefined
  }
  if (currentDate) {
    return date.format(new Date(currentDate), fmt)
  }
  return ``
}

export const getShiftedDate = (utcDate, timeShift) => {
  if (utcDate === null || utcDate === undefined) {
    return undefined
  }
  if (timeShift === null || timeShift === undefined) {
    return new Date(utcDate)
  }
  if (utcDate && timeShift) {
    let finalDate
    const locallyParsedDate = new Date(utcDate)
    const timezoneOffset = locallyParsedDate.getTimezoneOffset() * 60000
    // If the date string ends in 00:00:0000 js won't adapt the date to local time
    // For the other cases we have to shift local execution to utc to ensure it passes both
    // locally and in the server
    if (
      locallyParsedDate.getUTCHours() === 0 &&
      locallyParsedDate.getUTCMinutes() === 0 &&
      locallyParsedDate.getUTCSeconds() === 0
    ) {
      finalDate = new Date(locallyParsedDate.getTime() - (timeShift * -1000))
      return finalDate
    }
    finalDate = new Date(
      locallyParsedDate.getTime() - timezoneOffset - (timeShift * -1000)
    )
    return finalDate
  }
}

export const getShiftedDateNoOffset = (utcDate, timeShift) => {
  if (utcDate === null || utcDate === undefined) {
    return undefined
  }
  if (timeShift === null || timeShift === undefined) {
    return new Date(utcDate)
  }
  if (utcDate && timeShift) {
    const locallyParsedDate = new Date(utcDate.replace('+00:00', ''))
    const finalDate = new Date(locallyParsedDate.getTime() - (timeShift * -1000))
    return finalDate
  }
}

export const toDateWithoutOffset = currentDate => {
  let dt
  if (currentDate) {
    if (typeof currentDate === 'string') {
      dt = new Date(currentDate)
    } else {
      dt = new Date(currentDate.getTime())
    }
    return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  }
  return null
}
export const dateComparator = (date1, date2) => {
  const currentDate1 = toDateWithoutOffset(date1)
  const currentDate2 = toDateWithoutOffset(date2)

  if (currentDate1 === null && currentDate2 === null) {
    return 0
  }
  if (currentDate1 === null) {
    return -1
  }
  if (currentDate2 === null) {
    return 1
  }

  return date.subtract(currentDate1, currentDate2).toMilliseconds()
}

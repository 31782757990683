import { dateComparator, getNormalizedDate } from '../utils/date'
import { headerComponentParams, headerComponentParamsInfo } from './gridConfigUtils'

export const gridOptions = {
  columnDefs: [
    {
      headerName: 'Date',
      colId: 'date',
      comparator: dateComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) =>
          dateComparator(filterLocalDateAtMidnight, cellValue) * -1
      },
      valueGetter: ({ data }) =>
        data?.startedAt ? getNormalizedDate(data.startedAt, 'MM/DD/YYYY') : '',
      minWidth: 120
    },
    {
      headerName: 'Study Name',
      colId: 'study_name',
      valueGetter: ({ data }) => (data?.Study?.name ? data.Study.name : '')
    },
    {
      headerName: 'Site',
      colId: 'site',
      valueGetter: ({ data }) => (data?.Site?.name ? data.Site.name : '')
    },
    {
      headerName: 'Examiner',
      colId: 'examiner',
      valueGetter: ({ data }) => {
        if (data.User?.givenName && data.User?.familyName) {
          return `${data.User.givenName} ${data.User.familyName}`
        }
        return data?.User?.name ? data.User.name : ''
      }
    },
    {
      colId: 'examinee_id',
      field: `Examinee 
      Identifier`,
      valueGetter: ({ data }) => data?.Examinee?.identifier || '',
      headerComponentParams
    },
    {
      field: `Examinee 
      First Name`,
      colId: 'examinee_first_name',
      valueGetter: ({ data }) =>
        data?.Examinee?.firstName ? data.Examinee.firstName : '',
      headerComponentParams
    },
    {
      field: `Examinee 
      Last Name`,
      colId: 'examinee_last_name',
      valueGetter: ({ data }) =>
        data?.Examinee?.lastName ? data.Examinee.lastName : '',
      headerComponentParams
    },
    {
      field: `Examinee 
      DOB`,
      colId: 'examinee_dob',
      comparator: dateComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) =>
          dateComparator(filterLocalDateAtMidnight, cellValue) * -1
      },
      valueGetter: ({ data }) =>
        data?.Examinee?.dateOfBirth
          ? getNormalizedDate(data.Examinee.dateOfBirth, 'MM/DD/YYYY')
          : '',
      headerComponentParams,
      minWidth: 120
    },
    {
      field: `Status`,
      headerComponentParams: headerComponentParamsInfo,
      headerTooltip:
        'Archived encounters are uneditable and are removed from data exports. Usually archived encounters are practice encounters and/or do not contain data that is suitable for research or clinical use.',
      colId: 'isArchived',
      valueGetter: ({ data }) => (data?.isArchived ? 'Archived' : 'Active')
    }
  ],
  rowClassRules: {
    'encounter-archived-row': function (params) {
      return params.data.isArchived === true
    }
  }
}

import { useEffect, useState } from 'react'
import { useGlobalStore } from './useGlobalStore'
import { useRoleAccess } from './useRoleAccess'
import { GET_ENCOUNTERS_QUERY } from '../queries/getEncounters'
import { toaster } from 'evergreen-ui'
import { GET_ENCOUNTERS_ERROR_MESSAGE } from '../components/EncounterDetails/encounterUtils'
import { useQuery } from '@apollo/client'
import { navigate } from 'gatsby'

export function useHandleEncounterList({ encounterTable, routeRealm }) {
  const { realmId, setEncounterId } = useGlobalStore()
  const [gridApi, setGridApi] = useState()
  const [encountersSet, setEncountersSet] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [encountersSetRaw, setEncounterSetRaw] = useState([])
  const [selectedEncounter, setSelectedEncounter] = useState()
  const [showEncounterDetail, setShowEncounterDetail] = useState(false)
  const { canOpenEncounterDetails } = useRoleAccess()

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  const { loading, data: encounters } = useQuery(GET_ENCOUNTERS_QUERY, {
    skip: !realRealmId,
    variables: {
      realmId: realRealmId
    },
    onError: _ => toaster.danger(GET_ENCOUNTERS_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (encounters?.Encounter) {
      setEncountersSet(encounters?.Encounter)
      setEncounterSetRaw(encounters?.Encounter)
    }
  }, [encounters])

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay()
      } else if (!loading && encounters?.length === 0) {
        gridApi.showNoRowsOverlay()
      } else if (!loading && encounters?.length > 0) {
        gridApi.hideOverlay()
      }
    }
  }, [encounters, loading, gridApi])

  function handleEncounterFilter(query) {
    setFilterValue(query)

    if (query === '') {
      return setEncountersSet(encountersSetRaw)
    }

    setEncountersSet(
      encountersSetRaw.filter(encounter => {
        const name = `${encounter.User.familyName} ${encounter.User.givenName}`
        return name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
    )
  }

  function handleEncounterSelect({ data }) {
    if (canOpenEncounterDetails) {
      setSelectedEncounter(data)
      setEncounterId(data.id)
      setShowEncounterDetail(true)
      navigate(`/realm/${realRealmId}/encounter/${data.id}`)
      //encounterTable.current.classList.add('hide')
    }
  }

  function handleEncounterDetailsClose() {
    setShowEncounterDetail(false)
  }

  function onGridReady(params) {
    setGridApi(params?.api)
  }

  function onFilterChanged(params) {
    if (params?.api.getDisplayedRowCount() === 0) {
      gridApi.showNoRowsOverlay()
    } else if (params?.api.getDisplayedRowCount() > 0) {
      gridApi.hideOverlay()
    }
  }

  return {
    onFilterChanged,
    encountersSet,
    filterValue,
    selectedEncounter,
    showEncounterDetail,
    onGridReady,
    handleEncounterFilter,
    handleEncounterSelect,
    handleEncounterDetailsClose
  }
}

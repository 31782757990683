import React, { useRef, useEffect } from 'react'
import { login, isAuthenticated } from '../../../utils/auth'
import { Pane, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles,
  generalStyles,
  sidesheetStyles,
  formStyles
} from '../../../styles/sharedComponentStyles'
import CustomTable from '../../../components/CustomTable'
import { gridOptions } from '../../../gridConfig/encounterGridConfig'
import { useHandleEncounterList } from '../../../hooks/useHandleEncounterList'
import { useGlobalStore } from '../../../hooks/useGlobalStore'
import { getRealmIdFromURL } from '../../../utils/filters'
import { useLocation } from '@reach/router'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  ...sidesheetStyles,
  ...formStyles,
  encounterTable: {
    height: '100vh'
  }
})

const EncountersPage = ({ params }) => {
  const sharedClasses = useSharedStyles()
  const { setRealmPath } = useGlobalStore()
  const encounterTable = useRef(null)
  const location = useLocation()
  const routeRealm = location ? Number(getRealmIdFromURL(location)) : -1

  const {
    encountersSet,
    filterValue,
    onGridReady,
    onFilterChanged,
    handleEncounterFilter,
    handleEncounterSelect
  } = useHandleEncounterList({ encounterTable, routeRealm })

  useEffect(() => {
    setRealmPath('encounters')
  }, [setRealmPath])

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav}>
          <h1 data-testid="main-title">Encounters</h1>
          <Pane className={sharedClasses.autoComplete} display="flex">
            <TextInputField
              value={filterValue}
              label="Search by Examiner"
              onChange={e => handleEncounterFilter(e.target.value)}
              style={{
                padding: '20px'
              }}
            />
          </Pane>
        </Pane>
      </nav>
      <Pane className={sharedClasses.tableContainer} is="section">
        <Pane ref={encounterTable}>
          <CustomTable
            gridOptions={gridOptions}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              suppressMenu: true,
              minWidth: 100,
              tooltipValueGetter: params => params.value,
              flex: 2
            }}
            overlayLoadingTemplate={
              '<span style="position: absolute; top: 150px; font-size: 20px" className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span style="position: absolute; top: 150px; font-size: 20px" className="ag-overlay-loading-center">No encounters found.</span>'
            }
            onRowClicked={handleEncounterSelect}
            onGridReady={onGridReady}
            rowHeight={53}
            onFilterChanged={onFilterChanged}
            tableStyleClasses={[sharedClasses.encounterTable]}
            data={encountersSet.filter(({ dummyObj }) => !dummyObj)}
          />
        </Pane>
      </Pane>
    </>
  )
}

export default EncountersPage

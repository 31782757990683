import gql from 'graphql-tag'

export const GET_ENCOUNTERS_QUERY = gql`
  query EncounterQuery($realmId: Int!) {
    Encounter(
      where: { Study: { Realm: { id: { _eq: $realmId } } } }
      order_by: { startedAt: desc_nulls_last }
    ) {
      appVersion_id
      closedAt
      createdAt
      dataValidity
      dataValidityIssuesArray
      examinee_id
      id
      informant_id
      isArchived
      isClosed
      notes
      site_id
      startedAt
      study_id
      updatedAt
      user_id
      Study {
        id
        name
        isCollectingPHI
      }
      Site {
        name
        id
      }
      User {
        id
        name
        givenName
        familyName
      }
      Examinee {
        id
        dateOfBirth
        firstName
        gender
        handedness
        lastName
        patientCode
        preferredLanguage
        yearsOfEducation
        notes
        age
        testingLanguage
        identifier
      }
    }
  }
`
